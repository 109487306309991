/*
 * Copyright Schrodinger, LLC
 */
goog.require('goog.debug.LogRecord');
goog.require('goog.debug.Logger.Level');

/**
 * Extension of Closure's goog.debug.LogRecord to add a field for details.
 *
 * LogRecord objects are used to pass logging requests between
 * the logging framework and individual log Handlers.
 * If 0, we use #goog.now.
 * should only be passed in when restoring a log record from persistence.
 * @extends {goog.debug.LogRecord}
 */
class DebugLogRecord extends goog.debug.LogRecord {
  /**
   * @param {goog.debug.Logger.Level} level One of the level identifiers.
   * @param {string} msg The string message.
   * @param {string} loggerName The name of the source logger.
   * @param {string=} opt_detail Details about this message
   * @param {number=} opt_time Time this log record was created if other than now.
   * @param {number=} opt_sequenceNumber Sequence number of this log record. This
   */
  constructor(level, msg, loggerName, opt_detail, opt_time, opt_sequenceNumber) {
    super(level, msg, loggerName, opt_time, opt_sequenceNumber);
    /**
     * @type {?string}
     * @private
     */
    this.detail = null;
    if (goog.isDefAndNotNull(opt_detail)) {
      this.detail = opt_detail;
    }

    /**
     * @type {?string}
     * @private
     */
    this.stacktrace = null;
    // NOTE(mulvaney): Getting the stack trace of an error will cause sourceMaps to be downloaded in production.
    // TODO: Figure out if these stacktraces are useful on prod systems.
    if (level === goog.debug.Logger.Level.SEVERE) {
      const tempTrace = {};
      if (Error.captureStackTrace) {
        Error.captureStackTrace(tempTrace);
        this.stacktrace = tempTrace.stack;
      } else {
        var stack = new Error().stack;
        if (stack) {
          this.stacktrace = stack;
        }
      }
    }
  }

  /**
   * @inheritDoc
   */
  reset(level, msg, loggerName, opt_time, opt_sequenceNumber) {
    super.reset(level, msg, loggerName, opt_time, opt_sequenceNumber);
    delete this.detail;
  }

  /**
   * Get details about the logging message.
   *
   * @return {?string}
   */
  getDetail() {
    return this.detail;
  }

  /**
   * Set the logging message detail.
   *
   * @param {string} detail the details
   */
  setDetail(detail) {
    this.detail = detail;
  }

  /**
   * Get the stacktrace of the logging message.
   *
   * @return {?string}
   */
  getStacktrace() {
    return this.stacktrace;
  }
}

export default DebugLogRecord;
