/*
 * Copyright Schrodinger, LLC
 */
import DebugLogRecord from 'bb/debug/DebugLogRecord';
goog.require('goog.debug.Logger.Level');
goog.require('goog.format.JsonPrettyPrinter');
goog.require('goog.log');
goog.require('goog.log.Logger');

/**
 * TODO (jordan) Fix circular type dependency with bb/records/Error.js
 * @typedef {?}
 */
let ErrorRecord;

/**
 * Wrapper for Logger that uses DebugLogRecords to support details field.
 *
 */
class Logger {
  /**
   * @param {string} name The name of the Logger.
   * @private
   */
  constructor(name) {
    /**
     * @type {string}
     * @const
     * @private
     */
    this.name = name;

    /**
     * @type {goog.log.Logger}
     * @const
     * @private
     */
    this.logger = goog.log.getLogger(name);
  }

  /**
   * Logs a LogRecord.
   *
   * @param {DebugLogRecord} logRecord A LogRecord to log.
   */
  logRecord(logRecord) {
    if (this.logger) {
      this.logger.logRecord(logRecord);
    }
  }

  /**
   * Logs a XHR error.
   *
   * @param {goog.debug.Logger.Level} level One of the level identifiers.
   * @param {!ErrorRecord} error An exception associated with the message
   */
  logXhrError(level, error) {
    if (this.logger) {
      const jsonPrettyPrinter = new goog.format.JsonPrettyPrinter(null);
      const msg = error.getMessage() + ': ' + jsonPrettyPrinter.format(error.getFullDetails());
      this.log(level, msg);
    }
  }

  /**
   * Logs a message.
   *
   * @param {goog.debug.Logger.Level} level One of the level identifiers.
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message.
   * @param {Error|Object=} opt_exception An exception associated with the
   *     message.
   */
  log(level, msg, opt_details, opt_exception) {
    if (this.logger) {
      this.logger.logRecord(this.getLogRecord(level, msg, opt_details, opt_exception));
    }
  }

  /**
   * Returns the logger name
   *
   * @return {?string}
   */
  getName() {
    if (this.logger) {
      return this.logger.getName();
    }
    return null;
  }

  /**
   * Creates a new log record and adds the exception (if present) to it.
   * @param {goog.debug.Logger.Level} level One of the level identifiers.
   * @param {string} msg The string message.
   * @param {string=} opt_details The details associated with the message
   * @param {Error|Object=} opt_exception An exception associated with the
   *     message.
   * @return {!DebugLogRecord} A log record.
   * @private
   */
  getLogRecord(level, msg, opt_details, opt_exception) {
    const logRecord = new DebugLogRecord(level, String(msg), this.name, opt_details);
    if (opt_exception) {
      logRecord.setException(opt_exception);
    }
    return logRecord;
  }

  /**
   * Logs a message at the Logger.Level.SHOUT level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   */
  shout(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.SHOUT, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.SEVERE level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   * @export
   */
  severe(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.SEVERE, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.WARNING level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   * @export
   */
  warning(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.WARNING, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.INFO level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   * @export
   */
  info(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.INFO, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.CONFIG level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   */
  config(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.CONFIG, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.FINE level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   * @export
   */
  fine(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.FINE, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.FINER level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   * @export
   */
  finer(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.FINER, msg, opt_details, opt_exception);
  }

  /**
   * Logs a message at the Logger.Level.FINEST level.
   *
   * @param {string} msg The message to log.
   * @param {string=} opt_details The details associated with the message
   * @param {Error=} opt_exception An exception associated with the message.
   * @export
   */
  finest(msg, opt_details, opt_exception) {
    this.log(goog.debug.Logger.Level.FINEST, msg, opt_details, opt_exception);
  }

  /**
   * @param {string} name A dot-separated name for the Logger.
   * @return {!Logger}
   * @export
   */
  static getLogger(name) {
    return new Logger(name);
  }

  /**
   * Set the log level specifying which message levels will be logged by this
   * logger. Message levels lower than this value will be discarded.
   * The level value Level.OFF can be used to turn off logging. If the new level
   * is null, it means that this node should inherit its level from its nearest
   * ancestor with a specific (non-null) level value.
   *
   * @param {goog.debug.Logger.Level} level The new level.
   */
  setLevel(level) {
    this.logger.setLevel(level);
  }
}

goog.exportSymbol('bb.debug.Logger.getLogger', Logger.getLogger);
export default Logger;
