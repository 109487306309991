/*
 * Copyright Schrodinger, LLC
 */
/**
 * An enum for handling meta data around different browser request methods.
 
 * Includes default headers for crud requests that require JSON responses. For PUT and
 * POST we expect the upload to be Content-Type: application/json.
 *
 * @enum {{name: string, defaultHeaders: !Object}}
 */
const RequestMethod = {
  GET: {
    name: 'GET',
    defaultHeaders: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  },
  POST: {
    name: 'POST',
    defaultHeaders: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  },
  PUT: {
    name: 'PUT',
    defaultHeaders: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  },
  PATCH: {
    name: 'PATCH',
    defaultHeaders: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  },
  DELETE: {
    name: 'DELETE',
    defaultHeaders: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  },
};

export default RequestMethod;
